<template>
  <ion-page id="rolechats-page" class="page scrollable bg-transparent">
    <div class="d-flex align-items-center flex-wrap">
      <h1 class="m-0"><strong>Roleplay Chat</strong></h1>

      <ion-button v-if="rcProfileExists" color="primary" class="edit-btn inline-button ml-auto" @click="editProfile">
        Edit Profile
      </ion-button>
    </div>

    <top-banner banner-name="rolechatHomeHeader" />
    <rolechats-tabs
      class="my-2"
      :tabs="tabs"
      :tab-counts="tabCounts"
      :active-index="tabIndex"
      @tab-changed="tabChanged"
    />
    <active-rolechats v-if="currentTab === 'active'" @change-tab="changeTab"></active-rolechats>
    <rolechat-requests v-else-if="currentTab === 'requests'" @change-tab="changeTab"></rolechat-requests>
    <rolechat-discover v-else-if="currentTab === 'discover'" @change-tab="changeTab"></rolechat-discover>

    <rolechat-profile-form-modal
      :is-open="isProfileFormOpen"
      :is-edit="true"
      @dismiss-modal="closeProfileForm"
      @save-dismiss="closeSaveProfileForm"
    ></rolechat-profile-form-modal>
  </ion-page>
</template>

<script lang="ts" setup>
import TopBanner from '../home/components/TopBanner.vue';
import RolechatsTabs from './components/RolechatsTabs.vue';
import ActiveRolechats from './components/ActiveRolechats.vue';
import RolechatRequests from './components/RolechatRequests.vue';
import RolechatDiscover from './components/RolechatDiscover.vue';
import { Tab } from '@/shared/types/static-types';
import { getRolechatProfileStatus, getRolechatRequests } from '@/shared/actions/rolechats';
import RolechatProfileFormModal from '@/shared/modals/RolechatProfileFormModal.vue';
import store from '@/shared/store';
import { authStore } from '@/shared/pinia-store/auth';
import { toast } from '@/shared/native';

const tabs = ref([
  { name: 'Active', value: 'active' },
  { name: 'Requests', value: 'requests' },
  { name: 'Discover', value: 'discover' },
]);

const currentTab = ref('active');
const tabIndex = ref(0);
const rcReqCounts = ref(0);
const isProfileFormOpen = ref(false);
const rcProfileExists = ref(false);
const router = useRouter();

const getRolechats = (args: any) => store.dispatch('RolechatModule/getRolechats', args);

const rolechatsPaging = computed(() => {
  return store.getters['RolechatModule/rolechatsPaging'];
});

const tabCounts = computed(() => {
  return {
    active: rolechatsPaging.value.count,
    requests: rcReqCounts.value,
    discover: 0,
  };
});

const closeProfileForm = () => {
  if (isProfileFormOpen.value) {
    isProfileFormOpen.value = false;
  }
};

const closeSaveProfileForm = () => {
  isProfileFormOpen.value = false;
};

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  tabIndex.value = indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === value)
  );

  if (currentTab.value === 'requests') fetchReqCount();
  router.replace({ name: 'rolechats', query: { tab: value } });
};

const editProfile = () => {
  isProfileFormOpen.value = true;
};

const changeTab = ({ value }: Tab) => {
  const tIndex = indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === value)
  );
  if (tIndex !== -1) {
    tabIndex.value = tIndex;
    currentTab.value = value;
  }
};

const { userHasCharacters } = authStore();

const fetchReqCount = async () => {
  try {
    rcReqCounts.value = (await getRolechatRequests(1, 1)).count;
  } catch (_err) {
    // Handle if needed.
  }
};

const fetchRCProfileStatus = async () => {
  const resp = await getRolechatProfileStatus();
  rcProfileExists.value = get(resp, 'profile_exists', false);
};

watch(userHasCharacters, (val) => {
  if (!val) {
    router.push({ name: 'home' });
    toast.show('Create character first to enable roleplay chat', 'nonative', 'secondary');
  }
});

onMounted(() => {
  const document = useDocument();
  document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

  fetchRCProfileStatus();
  getRolechats({ from: 'rcpagecreated' });
  fetchReqCount();

  const tIndex = indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === router.currentRoute.value.query.tab)
  );
  if (router.currentRoute.value.query.tab && tIndex !== -1) {
    tabIndex.value = tIndex;
    currentTab.value = router.currentRoute.value.query.tab as string;
  }
});
</script>
<style lang="sass" scoped>
.infinite-home
  --background: transparent
  height: calc(100% - 58px - 40px)
  overflow: auto
  &::-webkit-scrollbar
    display: none

.edit-btn
  font-size: 14px
  color: #7f10b3
  font-weight: bold
  --border-radius: 10px
  --padding-start: 10px
  --padding-end: 10px
</style>
