<template>
  <div>
    <div v-if="rolechatRequests && rolechatRequests.length">
      <div v-for="request of rolechatRequests" :key="request.id" class="mb-4">
        <router-link
          :to="{ name: 'rolechat-req-details', params: { id: request.id } }"
          class="text-black clickable-item-hov"
        >
          <h4>{{ request.request_to.username }} / {{ request.request_from.username }} Roleplay Chat</h4>
          <div>Received: {{ formatDescDateTime(request.created) }}</div>
        </router-link>
      </div>

      <p v-if="nextPageExists" class="clickable-item-hov" @click="requestLoadMore">Load More</p>
    </div>
    <div v-else class="my-4">
      <div class="mb-2">No Active Roleplay Chat Requests.</div>
      <div>
        <router-link :to="{ name: 'rolechats', query: { tab: 'discover' } }" @click.prevent="goToDiscover"
          >CLICK HERE</router-link
        >
        to post a starter and start receiving requests!
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import { getRolechatRequests } from '@/shared/actions/rolechats';
import { formatDescDateTime } from '@/shared/utils/dateTime';

@Options({
  name: 'RolechatRequests',
  components: {},
})
export default class RolechatRequests extends Vue {
  public isLoading = false;

  get = get;
  formatDescDateTime = formatDescDateTime;

  rolechatRequests: any[] = [];
  paging = { next: null, previous: null, count: 0 };
  page = 1;

  public get nextPageExists() {
    return !!this.paging.next;
  }

  public async fetchRolechatRequests(page = 1) {
    this.isLoading = true;
    try {
      const { results, ...paging } = await getRolechatRequests(page);
      this.rolechatRequests = [...this.rolechatRequests, ...results];
      this.paging = paging;
      this.page = page;
    } catch (_err) {}
    this.isLoading = false;
  }

  public async rolechatRequestsNextPage() {
    this.isLoading = true;
    await getRolechatRequests(1);
    this.isLoading = false;
  }

  public async requestLoadMore(ev: CustomEvent) {
    if (!this.paging.next) {
      (ev?.target as any).complete();
    } else {
      await this.fetchRolechatRequests(this.page + 1);
    }
  }

  public goToDiscover() {
    this.$emit('changeTab', { value: 'discover' });
  }

  mounted() {
    this.fetchRolechatRequests();
  }
}
</script>

<style scoped lang="sass">
.unread-chat
  font-weight: bold
</style>
